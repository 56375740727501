/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <div className="content-wrapper" id="home">
      <header className="wrapper bg-soft-primary">
        <nav className="navbar fancy navbar-expand-lg navbar-light navbar-bg-light">
          <div className="container">
            <div className="navbar-collapse-wrapper bg-white d-flex flex-row flex-nowrap w-100 justify-content-between align-items-center">
              <div className="navbar-brand w-100">
                <NavLink to="/">
                  <img src="img/logo.png" alt="" />
                </NavLink>
              </div>
              <div className="navbar-collapse offcanvas-nav d-lg-flex mx-lg-auto">
                <div className="offcanvas-header d-lg-none d-xl-none">
                  <NavLink to="/">
                    <img
                      src="img/logo-light.png"
                      alt=""
                      style={{ width: 150 }}
                    />
                  </NavLink>
                  <button
                    type="button"
                    className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                    aria-label="Close"
                  ></button>
                </div>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link scroll" href="#home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link scroll" href="#services">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link scroll" href="#projects">
                      Projects
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link scroll" href="#about">
                      About us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="navbar-other w-100 d-flex ms-auto">
                <ul
                  className="navbar-nav flex-row align-items-center ms-auto"
                  data-sm-skip="true"
                >
                  {/* <li className="nav-item dropdown language-select text-uppercase">
                    <a
                      className="nav-link dropdown-item dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      En
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a className="dropdown-item" href="#">
                          EN
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="dropdown-item" href="#">
                          JP
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  <li className="nav-item d-none d-md-block">
                    <a
                      href="#contact"
                      className="btn btn-sm btn-primary rounded-pill scroll"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item d-lg-none">
                    <div className="navbar-hamburger">
                      <button
                        className="hamburger animate plain"
                        data-toggle="offcanvas-nav"
                      >
                        <span></span>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

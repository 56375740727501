/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function AboutUs() {
  return (
    <div>
      <section className="wrapper bg-light" id="about">
        <div className="container py-14 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-md-8 col-lg-6 col-xl-5 order-lg-2 position-relative">
              <div className="col-lg-12 order-lg-2">
                <ul className="progress-list">
                  <li>
                    <div className="post-category mb-2 text-yellow">Languages:</div>
                    <p className="mb-0">
                      C, C#, Javascript, SQL, Java, Python, Dart, PHP
                    </p>
                  </li>
                  <li>
                    <div className="post-category mb-2 text-blue">
                      Web development:
                    </div>
                    <p className="mb-0">
                      ReactJS, ASP.NET MVC, Laravel, NextJS, VueJS, HTML5, CSS3,
                      ArcGIS, Openstreet
                    </p>
                  </li>
                  <li>
                    <div className="post-category mb-2 text-red">
                      Back-end development:
                    </div>
                    <p className="mb-0">
                      NodeJS, ExpressJS, Ruby, GraphQL, Firebase, AWS, Python,
                      MongoDB
                    </p>
                  </li>
                  <li>
                    <div className="post-category mb-2 text-green">
                      Mobile development:
                    </div>
                    <p className="mb-0">React Native, Flutter</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <h2 className="display-4 mb-3">Who Are We?</h2>
              <p className="lead fs-lg">
                NOMADSOFT is a full-cycle web and mobile application development
                company that creates innovative solutions to build a better
                future for people and businesses and lead to success.
              </p>
              {/* <p className="mb-6">We are experts in Statistical Technology, Mobile/Web Enterprise Development and Consulting service.</p> */}
              <div className="row gx-xl-10 gy-6">
                <div className="col-md-12">
                  <div className="d-flex flex-row">
                    <div>
                      <img
                        src="img/icons/target.svg"
                        className="svg-inject icon-svg icon-svg-sm me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h4 className="mb-1">Our Mission</h4>
                      <p className="mb-0">
                        To become a leading web & app development and consulting
                        service provider at the global, leaded by brilliant
                        intellect.
                      </p>
                      <br />
                      <p className="mb-0">
                        To help enterprises accelerate adoption of new
                        technologies, untangle complex issues that always emerge
                        during digital evolution, and orchestrate ongoing
                        innovation.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6">
                <div className="d-flex flex-row">
                  <div>
                    <img src="img/icons/award-2.svg" className="svg-inject icon-svg icon-svg-sm me-4" alt="" />
                  </div>
                  <div>
                    <h4 className="mb-1">Our Values</h4>
                    <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.</p>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light wrapper-border">
        <div className="container py-14 py-md-8">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-4">
              <h2 className="fs-15 text-uppercase text-line text-primary text-center mb-3">
                Meet the Team
              </h2>
              <h4 className="display-5 mb-5">
                Save your time and money by choosing our professional team.
              </h4>
              <p>
                We are experts in Statistical Technology, Mobile/Web Enterprise
                Development and Consulting service.
              </p>
              {/* <a href="#" className="btn btn-primary rounded-pill mt-3">See All Members</a> */}
            </div>
            <div className="col-lg-8">
              <div className="carousel owl-carousel text-center" data-margin="30" data-dots="true" data-autoplay="false" data-autoplay-timeout="5000" data-responsive='{"0":{"items": "1"}, "768":{"items": "2"}, "992":{"items": "2"}, "1200":{"items": "3"}}'>
                <div className="item">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t4.jpg" srcSet="img/avatars/t4@2x.jpg 2x" alt="" />
                  <h4 className="mb-1">Yalalt Ganbat</h4>
                  <div className="meta mb-2">Project Manager</div>
                  {/* <p className="mb-2">Vivamus sagittis lacus vel augue laoreet rutrum.</p> */}
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="uil uil-facebook-f"></i></a>
                    <a href="#"><i className="uil uil-slack"></i></a>
                    <a href="#"><i className="uil uil-dribbble"></i></a>
                    <a href="#"><i className="uil uil-linkedin"></i></a>
                    <a href="#"><i className="uil uil-medium-m"></i></a>
                    
                  </nav>
                </div>
                <div className="item">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t5.jpg" srcSet="img/avatars/t5@2x.jpg 2x" alt="" />
                  <h4 className="mb-1">Erdenemunkh Tsenkherbor</h4>
                  <div className="meta mb-2">Fullstack Developer</div>
                  {/* <p className="mb-2">Etiam porta sem magna malesuada mollis.</p> */}
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="devicon-javascript-plain colored"></i></a>
                    <a href="#"><i className="devicon-typescript-plain colored"></i></a>
                    <a href="#"><i className="devicon-react-original colored"></i></a>
                    <a href="#"><i className="devicon-angularjs-plain colored"></i></a>
                    <a href="#"><i className="devicon-nextjs-original colored"></i></a>
                    <a href="#"><i className="devicon-socketio-original colored"></i></a>
                    <a href="#"><i className="devicon-php-plain colored"></i></a>
                    <a href="#"><i className="devicon-nodejs-plain colored"></i></a>
                    <a href="#"><i className="devicon-docker-plain colored"></i></a>
                    <a href="#"><i className="devicon-microsoftsqlserver-plain colored"></i></a>
                    <a href="#"><i className="devicon-mysql-plain colored"></i></a>
                    <a href="#"><i className="devicon-postgresql-plain colored"></i></a>
                    {/* <a href="#"><i className="devicon-oracle-original colored"></i></a>
                    <a href="#"><i className="devicon-firebase-plain colored"></i></a> */}
                    <a href="#"><i className="devicon-mongodb-plain colored"></i></a>
                    {/* <a href="#"><i className="devicon-amazonwebservices-original colored"></i></a> */}
                    {/* <a href="#"><i className="devicon-nginx-original colored"></i></a> */}
                    <a href="#"><i className="devicon-redis-plain colored"></i></a>
                  </nav>
                </div>
                <div className="item">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t1.jpg" srcSet="img/avatars/t1@2x.jpg 2x" alt="" />
                  <h4 className="mb-1">Jargal Ganbaatar</h4>
                  <div className="meta mb-2">Fullstack Developer</div>
                  {/* <p className="mb-2">Etiam porta sem magna malesuada mollis.</p> */}
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="devicon-csharp-plain colored"></i></a>
                    <a href="#"><i className="devicon-dot-net-plain-wordmark colored"></i></a>
                    <a href="#"><i className="devicon-javascript-plain colored"></i></a>
                    <a href="#"><i className="devicon-react-original colored"></i></a>
                    <a href="#"><i className="devicon-flutter-plain colored"></i></a>
                    <a href="#"><i className="devicon-dart-plain colored"></i></a>
                    <a href="#"><i className="devicon-docker-plain colored"></i></a>
                    <a href="#"><i className="devicon-microsoftsqlserver-plain colored"></i></a>
                    <a href="#"><i className="devicon-mysql-plain colored"></i></a>
                    <a href="#"><i className="devicon-postgresql-plain colored"></i></a>
                    <a href="#"><i className="devicon-oracle-original colored"></i></a>
                    <a href="#"><i className="devicon-firebase-plain colored"></i></a>
                    <a href="#"><i className="devicon-mongodb-plain colored"></i></a>
                  </nav>
                </div>
                <div className="item">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t2.jpg" srcSet="img/avatars/t2@2x.jpg 2x" alt="" />
                  <h4 className="mb-1">Oyunjargal Ganbaatar</h4>
                  <div className="meta mb-2">Backend Developer</div>
                  {/* <p className="mb-2">Aenean eu leo quam. Pellentesque ornare lacinia.</p> */}
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="devicon-csharp-plain colored"></i></a>
                    <a href="#"><i className="devicon-dot-net-plain-wordmark colored"></i></a>
                    <a href="#"><i className="devicon-javascript-plain colored"></i></a>
                    <a href="#"><i className="devicon-react-original colored"></i></a>
                    <a href="#"><i className="devicon-php-plain colored"></i></a>
                    <a href="#"><i className="devicon-nodejs-plain colored"></i></a>
                    <a href="#"><i className="devicon-docker-plain colored"></i></a>
                    <a href="#"><i className="devicon-microsoftsqlserver-plain colored"></i></a>
                    <a href="#"><i className="devicon-mysql-plain colored"></i></a>
                    <a href="#"><i className="devicon-mongodb-plain colored"></i></a>
                  </nav>
                </div>
                <div className="item">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t6.jpg" srcSet="img/avatars/t6@2x.jpg 2x" alt="" />
                  <h4 className="mb-1">Batbileg Battsengel</h4>
                  <div className="meta mb-2">Backend Developer</div>
                  {/* <p className="mb-2">Nullam risus eget urna mollis ornare vel eu leo.</p> */}
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="devicon-java-plain colored"></i></a>
                    <a href="#"><i className="devicon-spring-plain colored"></i></a>
                    <a href="#"><i className="devicon-dot-net-plain-wordmark colored"></i></a>
                    <a href="#"><i className="devicon-php-plain colored"></i></a>
                    <a href="#"><i className="devicon-laravel-plain colored"></i></a>
                    <a href="#"><i className="devicon-python-plain colored"></i></a>
                    <a href="#"><i className="devicon-ruby-plain colored"></i></a>
                    <a href="#"><i className="devicon-microsoftsqlserver-plain colored"></i></a>
                    <a href="#"><i className="devicon-mysql-plain colored"></i></a>
                    <a href="#"><i className="devicon-oracle-plain colored"></i></a>
                  </nav>
                </div>
                <div className="item">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t3.jpg" srcSet="img/avatars/t3@2x.jpg 2x" alt="" />
                  <h4 className="mb-1">Bat-Orshikh Bayartsogt</h4>
                  <div className="meta mb-2">Frontend Developer</div>
                  {/* <p className="mb-2">Donec ornare elit quam porta gravida at eget.</p> */}
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="devicon-javascript-plain colored"></i></a>
                    <a href="#"><i className="devicon-react-original colored"></i></a>
                    <a href="#"><i className="devicon-vuejs-plain colored"></i></a>
                    <a href="#"><i className="devicon-dot-net-plain-wordmark colored"></i></a>
                    <a href="#"><i className="devicon-php-plain colored"></i></a>
                    <a href="#"><i className="devicon-laravel-plain colored"></i></a>
                    <a href="#"><i className="devicon-python-plain colored"></i></a>
                    <a href="#"><i className="devicon-microsoftsqlserver-plain colored"></i></a>
                    <a href="#"><i className="devicon-mysql-plain colored"></i></a>
                    <a href="#"><i className="devicon-mongodb-plain colored"></i></a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="wrapper bg-light">
    <div className="container py-14 py-md-16">
      <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
        <div className="col-lg-4">
          <h2 className="fs-15 text-uppercase text-line text-primary text-center mb-3">Meet the Team</h2>
          <h3 className="display-5 mb-5">Save your time and money by choosing our professional team.</h3>
          <p>Donec id elit non mi porta gravida at eget metus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros tempus porttitor.</p>
          <a href="#" className="btn btn-primary rounded-pill mt-3">See All Members</a>
        </div>
        
        <div className="col-lg-8">
      
            <div className="item">
              <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t1.jpg" srcSet="img/avatars/t1@2x.jpg 2x" alt="" />
              <h4 className="mb-1">Cory Zamora</h4>
              <div className="meta mb-2">Marketing Specialist</div>
              <p className="mb-2">Etiam porta sem magna malesuada mollis.</p>
              <nav className="nav social justify-content-center text-center mb-0">
                <a href="#"><i className="uil uil-twitter"></i></a>
                <a href="#"><i className="uil uil-slack"></i></a>
                <a href="#"><i className="uil uil-linkedin"></i></a>
              </nav>
              
            </div>
            
            <div className="item">
              <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t2.jpg" srcSet="img/avatars/t2@2x.jpg 2x" alt="" />
              <h4 className="mb-1">Coriss Ambady</h4>
              <div className="meta mb-2">Financial Analyst</div>
              <p className="mb-2">Aenean eu leo quam. Pellentesque ornare lacinia.</p>
              <nav className="nav social justify-content-center text-center mb-0">
                <a href="#"><i className="uil uil-youtube"></i></a>
                <a href="#"><i className="uil uil-facebook-f"></i></a>
                <a href="#"><i className="uil uil-dribbble"></i></a>
              </nav>
              
            </div>
            
            <div className="item">
              <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t3.jpg" srcSet="img/avatars/t3@2x.jpg 2x" alt="" />
              <h4 className="mb-1">Nikolas Brooten</h4>
              <div className="meta mb-2">Sales Manager</div>
              <p className="mb-2">Donec ornare elit quam porta gravida at eget.</p>
              <nav className="nav social justify-content-center text-center mb-0">
                <a href="#"><i className="uil uil-linkedin"></i></a>
                <a href="#"><i className="uil uil-tumblr-square"></i></a>
                <a href="#"><i className="uil uil-facebook-f"></i></a>
              </nav>
              
            </div>
            
            <div className="item">
              <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t4.jpg" srcSet="img/avatars/t4@2x.jpg 2x" alt="" />
              <h4 className="mb-1">Jackie Sanders</h4>
              <div className="meta mb-2">Investment Planner</div>
              <p className="mb-2">Nullam risus eget urna mollis ornare vel eu leo.</p>
              <nav className="nav social justify-content-center text-center mb-0">
                <a href="#"><i className="uil uil-twitter"></i></a>
                <a href="#"><i className="uil uil-facebook-f"></i></a>
                <a href="#"><i className="uil uil-dribbble"></i></a>
              </nav>
              
            </div>
            
            <div className="item">
              <img className="rounded-circle w-20 mx-auto mb-4" src="img/avatars/t5.jpg" srcSet="img/avatars/t5@2x.jpg 2x" alt="" />
              <h4 className="mb-1">Tina Geller</h4>
              <div className="meta mb-2">Assistant Buyer</div>
              <p className="mb-2">Vivamus sagittis lacus vel augue laoreet rutrum.</p>
              <nav className="nav social justify-content-center text-center mb-0">
                <a href="#"><i className="uil uil-facebook-f"></i></a>
                <a href="#"><i className="uil uil-slack"></i></a>
                <a href="#"><i className="uil uil-dribbble"></i></a>
              </nav>
              
            </div> 
            
          </div>
          
        </div>
        
      </div>
      
    
    
    </section> */}
    </div>
  );
}

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Pro5() {
  return (
    <div>
      <section class="wrapper bg-soft-primary">
        <div class="container pt-10 pb-19 pt-md-14 pb-md-22 text-center">
          <div class="row">
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div class="post-header">
                <div class="post-category text-line">
                  <a href="#" class="hover" rel="category">
                    Project
                  </a>
                </div>
                <h1 class="display-1 mb-3">Flight Management System</h1>
                <p class="lead px-md-12 px-lg-12 px-xl-15 px-xxl-18">
                  It is a comprehensive air traffic control system in Mongolia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="wrapper bg-light wrapper-border">
        <div class="container pb-14 pb-md-16">
          <div class="row">
            <div class="col-12">
              <article class="mt-n21">
                <div class="text-center">
                  <figure class="rounded mb-12 mb-md-12">
                    <img src="img/photos/order.jpeg" alt="" />
                  </figure>
                </div>
                <div class="row">
                  <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-6 mb-4">About the Project</h2>
                    <div class="row gx-0">
                      <div class="col-md-9 text-justify">
                        <p>
                          The Flight Scheduling System is a system for planning,
                          recording, and monitoring all flights in Mongolian
                          airspace, as well as calculating airspace congestion,
                          receiving data from the AFTN network automatically,
                          invoicing, and producing statistics and reports.
                        </p>
                      </div>
                      <div class="col-md-2 ms-auto">
                        <ul class="list-unstyled">
                          <li>
                            <h5 class="mb-1">Client Name</h5>
                            <p>Civil Aviation Authority</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Date</h5>
                            <p>2016</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Technology</h5>
                            <p>PHP, Laravel, Oracle</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/orderpoint.jpeg" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/organizations.jpeg" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/orderlist.jpeg" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/notifications.jpeg" alt="" />
                    </figure>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

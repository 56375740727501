/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main";
import Projects from "./components/Projects";
import Services from "./components/Services";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import Pro1 from "./projects/Pro1";
import Pro2 from "./projects/Pro2";
import Pro3 from "./projects/Pro3";
import Pro4 from "./projects/Pro4";
import Pro5 from "./projects/Pro5";
import Pro6 from "./projects/Pro6";

export default function App() {
  return (
    <div>
      <Header />
      <Switch>
        <Route path="/projectone" component={Pro1} />
        <Route path="/projecttwo" component={Pro2} />
        <Route path="/projectthree" component={Pro3} />
        <Route path="/projectfour" component={Pro4} />
        <Route path="/projectfive" component={Pro5} />
        <Route path="/projectsix" component={Pro6} />
        <Route path="/">
          <Main />
          <Services />
          <Projects />
          <AboutUs />
          <Contact />
        </Route>
      </Switch>
      <Footer />
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
  );
}

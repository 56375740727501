import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Pro6() {
  return (
    <div>
      <section class="wrapper bg-soft-primary">
        <div class="container pt-10 pb-19 pt-md-14 pb-md-22 text-center">
          <div class="row">
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div class="post-header">
                <div class="post-category text-line">
                  <a href="#" class="hover" rel="category">
                    Project
                  </a>
                </div>
                <h1 class="display-1 mb-3">Performance & Evaluation</h1>
                <p class="lead px-md-12 px-lg-12 px-xl-15 px-xxl-18"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="wrapper bg-light wrapper-border">
        <div class="container pb-14 pb-md-16">
          <div class="row">
            <div class="col-12">
              <article class="mt-n21">
                <div class="text-center">
                  <figure class="rounded mb-12 mb-md-12">
                    <img src="img/photos/m5.png" alt="" />
                  </figure>
                </div>
                <div class="row">
                  <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-6 mb-4">About the Project</h2>
                    <div class="row gx-0">
                      <div class="col-md-9 text-justify">
                        <p>
                          A wide range of planning and policy implementation, as
                          well as its results and budget expenditures, will be
                          coordinated in phases to promote the welfare of
                          herders, and herders will be given with integrated
                          rules and regulations.
                        </p>
                        <p>
                          This method was created to help people assess, plan,
                          implement, and report on their investment needs.
                        </p>
                      </div>
                      <div class="col-md-2 ms-auto">
                        <ul class="list-unstyled">
                          {/* <li>
                            <h5 class="mb-1">Client Name</h5>
                            <p></p>
                          </li> */}
                          <li>
                            <h5 class="mb-1">Date</h5>
                            <p>2020</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Technology</h5>
                            <p>ASP.NET MVC, SQL Server, ArcGIS Map Service</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/m7.png" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/m8.png" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/m9.png" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/m10.png" alt="" />
                    </figure>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Pro2() {
  return (
    <div>
      <section class="wrapper bg-soft-primary">
        <div class="container pt-10 pb-19 pt-md-14 pb-md-22 text-center">
          <div class="row">
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div class="post-header">
                <div class="post-category text-line">
                  <a href="#" class="hover" rel="category">
                    Project
                  </a>
                </div>
                <h1 class="display-1 mb-3">E-Survey</h1>
                <p class="lead px-md-12 px-lg-12 px-xl-15 px-xxl-18">
                  An easy-to-use system for gathering, processing, and
                  disseminating the data you need to run your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="wrapper bg-light wrapper-border">
        <div class="container pb-14 pb-md-16">
          <div class="row">
            <div class="col-12">
              <article class="mt-n21">
                <div class="text-center">
                  <figure class="rounded mb-12 mb-md-12">
                    <img src="img/photos/esurvey1.png" alt="" />
                  </figure>
                </div>
                <div class="row">
                  <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-6 mb-4">About the Project</h2>
                    <div class="row gx-0">
                      <div class="col-md-9 text-justify">
                        <p>
                          Using your own and expert-designed questionnaire
                          templates, you may quickly and easily develop your own
                          survey and registration questionnaire. Design it to
                          match your company's or brand's color and style.
                        </p>
                        <p>
                          You can look for information on social media sites,
                          webpages, emails, and QR codes, among other things.
                          You can contact your target audience through the
                          channel of your choice. The simplest format for
                          completing the survey is the most comprehensive
                          approach for increasing the number of survey
                          participants.
                        </p>
                        <p>
                          Every hour and minute that the survey is finished, you
                          can monitor, edit, and analyze it. This can be done
                          without the need of a particular analysis program.
                        </p>
                      </div>
                      <div class="col-md-2 ms-auto">
                        <ul class="list-unstyled">
                          <li>
                            <h5 class="mb-1">Client Name</h5>
                            <p>SICA LLC</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Date</h5>
                            <p>2019</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Technology</h5>
                            <p>React JS, Node JS</p>
                          </li>
                        </ul>
                        {/* <a href="#" class="more hover">
                          See Project
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/esurvey1.png" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/esurvey2.png" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/esurvey3.png" alt="" />
                    </figure>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

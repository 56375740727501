import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Pro3() {
  return (
    <div>
      <section class="wrapper bg-soft-primary">
        <div class="container pt-10 pb-19 pt-md-14 pb-md-22 text-center">
          <div class="row">
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div class="post-header">
                <div class="post-category text-line">
                  <a href="#" class="hover" rel="category">
                    Project
                  </a>
                </div>
                <h1 class="display-1 mb-3">Shipping Call</h1>
                <p class="lead px-md-12 px-lg-12 px-xl-15 px-xxl-18">
                  The shipping call platform is a complete system that
                  encompasses freight, lifting equipment, construction
                  equipment, heavy equipment, and bus rental.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="wrapper bg-light wrapper-border">
        <div class="container pb-14 pb-md-16">
          <div class="row">
            <div class="col-12">
              <article class="mt-n21">
                <div class="text-center">
                  <figure class="rounded mb-12 mb-md-12">
                    <img src="img/photos/S1.png" alt="" />
                  </figure>
                </div>
                <div class="row">
                  <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-6 mb-4">About the Project</h2>
                    <div class="row gx-0">
                      <div class="col-md-9 text-justify">
                        <p>
                          The shipping call platform is a complete system that
                          encompasses freight, lifting equipment, construction
                          equipment, heavy equipment, and bus rental.
                        </p>
                        <p>
                          Clients and firms that provide transportation services
                          to customers nationally will be able to use the
                          platform to build bridges, and customers will be able
                          to access fast transportation services such as
                          freight, lifting equipment, construction machinery,
                          and heavy machinery.
                        </p>
                      </div>
                      <div class="col-md-2 ms-auto">
                        <ul class="list-unstyled">
                          <li>
                            <h5 class="mb-1">Client Name</h5>
                            <p>ShippingCall LLC</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Date</h5>
                            <p>2021</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Technology</h5>
                            <p>Flutter, Firebase, Google Cloud</p>
                          </li>
                        </ul>
                        {/* <a href="#" class="more hover">
                          See Project
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/S2.png" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-6">
                    <figure class="rounded">
                      <img src="img/photos/S3.png" alt="" />
                    </figure>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

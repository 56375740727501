import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Pro1() {
  return (
    <div>
      <section class="wrapper bg-soft-primary">
        <div class="container pt-10 pb-19 pt-md-14 pb-md-22 text-center">
          <div class="row">
            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div class="post-header">
                <div class="post-category text-line">
                  <a href="#" class="hover" rel="category">
                    Project
                  </a>
                </div>
                <h1 class="display-1 mb-3">Dashichoiling</h1>
                <p class="lead px-md-12 px-lg-12 px-xl-15 px-xxl-18">
                  For Mongolia's largest monastery, we created a book ordering
                  system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="wrapper bg-light wrapper-border">
        <div class="container pb-14 pb-md-16">
          <div class="row">
            <div class="col-12">
              <article class="mt-n21">
                <div class="text-center">
                  <figure class="rounded mb-12 mb-md-12">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/jE0S0wg0-24"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </figure>
                </div>
                <div class="row">
                  <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-6 mb-4">About the Project</h2>
                    <div class="row gx-0">
                      <div class="col-md-9 text-justify">
                        <p>
                          Separate religious and religious events and training
                          are held on a regular basis at Zuun Khuree Dashchoilin
                          Monastery. Daily sermons, congregational meetings, and
                          monthly, quarterly, and annual equinoxes are all held
                          at the monastery.
                        </p>
                        <p>
                          Users can use this app to look up monastery book
                          reading hours, daily information, and place online
                          book orders. Customers can order books from any
                          location and pay for them using an online payment
                          system.
                        </p>
                      </div>
                      <div class="col-md-2 ms-auto">
                        <ul class="list-unstyled">
                          <li>
                            <h5 class="mb-1">Client Name</h5>
                            <p>Dashichoiling</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Date</h5>
                            <p>2019</p>
                          </li>
                          <li>
                            <h5 class="mb-1">Technology</h5>
                            <p>React JS, Node JS</p>
                          </li>
                        </ul>
                        {/* <a href="#" class="more hover">
                          See Project
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                  <div class="item col-md-4">
                    <figure class="rounded">
                      <img src="img/photos/pd_1.jpg" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-4">
                    <figure class="rounded">
                      <img src="img/photos/pd1_2.jpg" alt="" />
                    </figure>
                  </div>
                  <div class="item col-md-4">
                    <figure class="rounded">
                      <img src="img/photos/pd1_3.jpg" alt="" />
                    </figure>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import React from "react";

export default function Main() {
  return (
    <section className="wrapper bg-gradient-primary wrapper-border">
      <div className="container py-14 py-md-12">
        <div className="row gx-2 gy-10 align-items-center">
          <div
            className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start order-2 order-lg-0"
            data-cues="slideInDown" data-group="page-title" data-delay="600"
          >
            <h1 className="display-1 mb-5 mx-md-10 mx-lg-0">
              Build an intelligently designed digital future <br />
              {/* <span
                className="typer text-primary text-nowrap"
                data-delay="100"
                data-delim=":"
                data-words="your business.:your portfolio.:your startup.:digital marketing."
              ></span>
              <span className="cursor text-primary" data-owner="typer"></span> */}
            </h1>
            <p className="lead fs-23 mb-7">
              NOMADSOFT is a full-cycle web and mobile application development
              company that creates innovative solutions to build a better future
              for people and businesses and lead to success.
            </p>
            <div
              className="d-flex justify-content-center justify-content-lg-start mb-4"
              data-cues="slideDown"
              data-group="page-title-buttons"
              data-delay="900"
            >
              <span>
                <a
                  className="btn btn-lg btn-primary rounded-pill me-2 scroll"
                  href="#services"
                >
                  Check our services
                </a>
              </span>
            </div>
          </div>
          <div className="col-lg-6">
            <figure>
              <img
                className="w-auto"
                src="img/concept/concept2.png"
                srcSet="img/concept/concept2@2x.png 2x"
                alt=""
              />
            </figure>
          </div>

          {/* <div className="col-lg-6 ms-auto position-relative">
            <div className="row g-4">
              <div className="col-4 d-flex flex-column ms-auto" data-cues="" data-group="col-start" data-delay="900">
                <div className="ms-auto mt-6"><img className="img-fluid rounded shadow-lg" src="img/demos/vc1.jpg" srcSet="img/demos/vc1@2x.jpg 2x" alt="" /></div>
                <div className="ms-auto mt-4"><img className="img-fluid rounded shadow-lg" src="img/demos/vc2.jpg" srcSet="img/demos/vc2@2x.jpg 2x" alt="" /></div>
                <div className="ms-auto mt-4"><img className="img-fluid rounded shadow-lg" src="img/demos/vc3.jpg" srcSet="img/demos/vc3@2x.jpg 2x" alt="" /></div>
              </div>
              <div className="col-4" data-cues="" data-group="col-middle">
                <div><img className="w-100 img-fluid rounded shadow-lg" src="img/demos/vc4.jpg" srcSet="img/demos/vc4@2x.jpg 2x" alt="" /></div>
                <div><img className="w-100 img-fluid rounded shadow-lg mt-4" src="img/demos/vc5.jpg" srcSet="img/demos/vc5@2x.jpg 2x" alt="" /></div>
                <div><img className="w-100 img-fluid rounded shadow-lg mt-4" src="img/demos/vc6.jpg" srcSet="img/demos/vc6@2x.jpg 2x" alt="" /></div>
              </div>
              <div className="col-4 d-flex flex-column" data-cues="" data-group="col-end" data-delay="900">
                <div className="mt-8"><img className="img-fluid rounded shadow-lg" src="img/demos/vc7.jpg" srcSet="img/demos/vc7@2x.jpg 2x" alt="" /></div>
                <div className="mt-4 mb-10"><img className="img-fluid rounded shadow-lg" src="img/demos/vc8.jpg" srcSet="img/demos/vc8@2x.jpg 2x" alt="" /></div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

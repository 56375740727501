import React from "react";

export default function Services() {
  return (
    <section className="wrapper bg-light wrapper-border" id="services">
      <div className="container py-14 py-md-15">
        <div className="row text-center">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <h2 className="fs-15 text-uppercase text-primary mb-3">
              What We Do?
            </h2>
            <h3 className="display-4 mb-10 px-xl-10">
              The service we offer is specifically designed to meet your needs.
            </h3>
          </div>
        </div>
        <div className="position-relative">
          <div
            className="shape rounded-circle bg-soft-primary rellax w-16 h-16"
            data-rellax-speed="1"
            style={{ bottom: "-0.5rem", right: "-2.5rem", zIndex: 0 }}
          ></div>
          <div
            className="shape bg-dot primary rellax w-16 h-17"
            data-rellax-speed="1"
            style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
          ></div>
          <div className="row gx-md-5 gy-5 text-center">
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon btn btn-circle btn-lg btn-primary disabled mb-3">
                    {" "}
                    <i className="uil uil-cloud-computing"></i>{" "}
                  </div>
                  <h4>Web Development</h4>
                  <p className="mb-2">
                    From design to integration and maintenance, we offer
                    full-cycle web application development services. We build
                    apps that are secure, scalable, fast, and work well across
                    all devices and platforms.
                  </p>
                  {/* <a href="!#" className="more hover link-primary">Learn More</a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon btn btn-circle btn-lg btn-primary disabled mb-3">
                    {" "}
                    <i className="uil uil-mobile-android"></i>{" "}
                  </div>
                  <h4>App Development</h4>
                  <p className="mb-2">
                    We offer a full cycle of application design, integration and
                    management services. Whether it is a consumer oriented app
                    or a transformative enterprise-class solution, the company
                    leads the entire mobile app development process from
                    ideation and concept to delivery.
                  </p>
                  {/* <a href="!#" className="more hover link-primary">Learn More</a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon btn btn-circle btn-lg btn-primary disabled mb-3">
                    {" "}
                    <i className="uil uil-map"></i>{" "}
                  </div>
                  <h4>GIS Development</h4>
                  <p className="mb-2">
                    We offer a development of GIS solution, integration and
                    management services. Whether it is a GIS based application or a enterprise web solution, 
                    we have abundant experience of delivering relevant services in this area.
                  </p>
                  {/* <a href="!#" className="more hover link-primary">Learn More</a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="icon btn btn-circle btn-lg btn-primary disabled mb-3">
                    {" "}
                    <i className="uil uil-lightbulb"></i>{" "}
                  </div>
                  <h4>IT Consultant</h4>
                  <p className="mb-2">
                    We offer IT consulting services that will help you improve
                    your software architecture, create a tech-driven digital
                    strategy, and improve operations by optimising your software
                    portfolio.
                  </p>
                  {/* <a href="!#" className="more hover link-primary">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { NavLink } from "react-router-dom";

export default function Projects() {
  return (
    <section className="wrapper bg-light wrapper-border" id="projects">
      <div className="container py-14 py-md-15">
        <div className="row align-items-center mb-7">
          <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 pe-lg-17">
            <h2 className="display-4 mb-3">Recent Projects</h2>
            <p className="lead">
              We are a digital transformation consultancy and software
              development company that provides cutting edge engineering
              solutions.
            </p>
          </div>
        </div>
        <div className="grid grid-view projects-masonry">
          <div className="row gx-md-8 gy-10 gy-md-13 isotope">
            <div className="project item col-md-6 col-xl-4 product">
              <div className="project-details d-flex justify-content-center flex-column">
                <div className="post-header">
                  <div className="post-category mb-2 text-yellow">Religion</div>
                  <h2 className="post-title h3">Dashichoiling</h2>
                </div>
              </div>
              <figure className="lift rounded mb-6">
                <NavLink to="/projectone">
                  <img src="img/photos/pd1.jpg" alt="" />
                </NavLink>
              </figure>
            </div>
            <div className="project item col-md-6 col-xl-4 workshop">
              {" "}
              <div className="project-details d-flex justify-content-center flex-column">
                <div className="post-header">
                  <div className="post-category mb-2 text-red">Survey</div>
                  <h2 className="post-title h3">E-Survey</h2>
                </div>
              </div>
              <figure className="lift rounded mb-6">
                <NavLink to="/projecttwo">
                  <img src="img/photos/esurvey2.png" alt="" />
                </NavLink>
              </figure>
            </div>
            <div className="project item col-md-6 col-xl-4 still-life">
              {" "}
              <div className="project-details d-flex justify-content-center flex-column">
                <div className="post-header">
                  <div className="post-category mb-2 text-orange">
                    Transport
                  </div>
                  <h2 className="post-title h3">Shipping Call</h2>
                </div>
              </div>
              <figure className="lift rounded mb-6">
                <NavLink to="/projectthree">
                  <img src="img/photos/pd3.png" alt="" />
                </NavLink>
              </figure>
            </div>

            <div className="project item col-md-6 col-xl-4 product">
              <div className="project-details d-flex justify-content-center flex-column">
                <div className="post-header">
                  <div className="post-category mb-2 text-purple">Product</div>
                  <h2 className="post-title h3">Flight Management System</h2>
                </div>
              </div>
              <figure className="lift rounded mb-6">
                <NavLink to="/projectfive">
                  <img src="img/photos/pd5.jpeg" alt="" />
                </NavLink>
              </figure>
            </div>

            <div className="project item col-md-6 col-xl-4 product">
              {" "}
              <div className="project-details d-flex justify-content-center flex-column">
                <div className="post-header">
                  <div className="post-category mb-2 text-leaf">Mapping</div>
                  <h2 className="post-title h3">License Management System</h2>
                </div>
              </div>
              <figure className="lift rounded mb-6">
                <NavLink to="/projectfour">
                  <img src="img/photos/pd6.png" alt="" />
                </NavLink>
              </figure>
            </div>

            <div className="project item col-md-6 col-xl-4 workshop">
              <div className="project-details d-flex justify-content-center flex-column">
                <div className="post-header">
                  <div className="post-category mb-2 text-aqua">Monitoring</div>
                  <h2 className="post-title h3"> Performance & Evaluation </h2>
                </div>
              </div>
              <figure className="lift rounded mb-6">
                <NavLink to="/projectsix">
                  <img src="img/photos/pd4.png" alt="" />
                </NavLink>
              </figure>
            </div>
            {/* <div className="project item col-md-6 col-xl-4 workshop">
              {" "}
              <div className="project-details d-flex justify-content-center flex-column">
                <div className="post-header">
                  <div className="post-category mb-2 text-red">Property</div>
                  <h2 className="post-title h3">Real Estate</h2>
                </div>
              </div>
              <figure className="lift rounded mb-6">
                <a href="#">
                  {" "}
                  <img src="img/photos/pd2.png" alt="" />
                </a>
              </figure>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
